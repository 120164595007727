import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/TeamMemberLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Alle Carter has been involved with CLHbid.com since its inception. Alle obtained her Law Degree in Queensland, Australia. Alle grew up with a strong connection to the family farm where often she could be seen next to her Mom in the combine. Always a high achiever whether that be on the honor roll in High School, or ensuring every project she takes on is completed to perfection. Taking an interest in software media design programs at an early age, she has a discerning eye to present your property in its most appealing perspective.`}</p>
    <p>{`Her passion in working with CLHbid.com clients is evident from that very first meeting with the client right down to her ensuring all the details are covered for sale day. Alle has a particular ability to connect with clients of CLHbid.com recognizing the significance a sale will have on their life. Alle’s legal background allows her to bring added value to clients on a multitude of issues including things such as consolidation of titles, subdivision of farmsteads, partial discharges of mortgages, legal access issues and vendor take back financing to vendors at rates above bank rates.`}</p>
    <p>{`Alle’s hobbies include gardening, fishing, river boating, team penning, roping, camping as well as spending time with her nieces and nephew.`}</p>
    <p>{`Alle is an accomplished show jumping equestrian having competed at a high level throughout Western USA, Western Canada and New Zealand.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      